import type { SupabaseClient } from '@supabase/supabase-js';
import xss from 'xss';
import type { Cookies } from '@sveltejs/kit';
import type { Coordinate } from '$lib/types';

export function getInitials(name: string | null | undefined): string {
	if (name === undefined || name === null) {
		return '?';
	}

	return name
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase())
		.join('');
}

export function descriptionPreview(description: string) {
	return description.length > 100 ? description.slice(0, 100) + '...' : description;
}

export function getPageSettings(searchParams: URLSearchParams): { page: number; limit: number } {
	let page = Number(searchParams.get('page')) || 0;
	let limit = Number(searchParams.get('limit')) || 10;
	return { page, limit };
}

export function getUserLocation(cookies: Cookies): Coordinate | undefined {
	const lat = cookies.get('user_lat') ? Number(cookies.get('user_lat')) : null;
	const lng = cookies.get('user_lng') ? Number(cookies.get('user_lng')) : null;
	if (lat === null || lng === null) {
		return undefined;
	}
	return { lat, lng };
}

export function getPublicURL(supabase: SupabaseClient, path: string): string | undefined {
	return supabase.storage.from('listings').getPublicUrl(path, {
		// transform: {
		// 	width: 400,
		// 	height: 400
		// }
	}).data.publicUrl;
}

export function sanitize(content: string): string {
	return xss(content);
}

export function isEventCategory(categorySlug: string): boolean {
	const eventCategories = ['events', 'classes-courses'];
	return eventCategories.includes(categorySlug);
}

export function formatDateToUserTimeZone(date: string): string {
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // User's local time zone
	};

	return new Intl.DateTimeFormat('en-US', options).format(Date.parse(date));
}
