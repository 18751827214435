<script lang="ts">
	import '../app.postcss';
	import { initializeStores, Modal, type ModalComponent, storePopup } from '@skeletonlabs/skeleton';
	import { onMount } from 'svelte';
	import { invalidate } from '$app/navigation';
	import { arrow, autoUpdate, computePosition, flip, offset, shift } from '@floating-ui/dom';
	import BottomNav from '$lib/components/core/BottomNav.svelte';
	import { profileStore } from '$lib/stores';
	import ProfilesRepository from '$lib/repositories/ProfilesRepository';
	import { MetaTags } from 'svelte-meta-tags';
	import extend from 'just-extend';
	import { page } from '$app/stores';
	import TopNav from '$lib/components/core/TopNav.svelte';
	import ListingsSearch from '$lib/components/listing/ListingsSearchModal.svelte';
	import ListingShare from '$lib/components/listing/ListingShare.svelte';
	import { setCurrentLocation } from '$lib/utils/location';

	initializeStores();
	storePopup.set({ computePosition, autoUpdate, flip, shift, offset, arrow });

	export let data;

	let { supabase, session, profile } = data;
	$: ({ supabase, session, profile } = data);

	if (profile !== null) {
		profile.then((data) => profileStore.set(data));
	}

	const modalComponentRegistry: Record<string, ModalComponent> = {
		modalSearch: {
			ref: ListingsSearch,
			props: { supabase: supabase }
		},
		modalShare: {
			ref: ListingShare,
			props: { supabase: supabase }
		}
	};

	onMount(() => {
		const repository = new ProfilesRepository(supabase);
		const { data } = supabase.auth.onAuthStateChange((event, _session) => {
			if (_session?.expires_at !== session?.expires_at || event === 'SIGNED_OUT') {
				profileStore.set(null);
				invalidate('supabase:auth');
			}
			if (event === 'USER_UPDATED' || event === 'SIGNED_IN') {
				if (_session?.user.id) {
					invalidate('supabase:auth');
					repository.getById(_session.user.id).then((data) => profileStore.set(data));
				}
			}
		});

		setCurrentLocation();

		return () => data.subscription.unsubscribe();
	});

	$: metaTags = extend(true, {}, data.baseMetaTags, $page.data.pageMetaTags);
</script>

<MetaTags {...metaTags} />

<Modal components={modalComponentRegistry} />

<div class="h-screen grid grid-rows-[auto_1fr_auto]">
	<header class="sticky top-0 z-10">
		<TopNav isLoggedIn={!!session} />
	</header>

	<main class="container mx-auto p-4">
		<slot />

		<footer class="w-full py-8 card text-center mt-12">
			<p class="text-gray-300 text-sm">
				The Resonance* Website is not-for-profit and relies on your Koha contributions. Your support
				helps us build this platform and connect more people in the community.
			</p>
			<p class="text-white mt-4">
				Donate via <a
				class="text-indigo-400 hover:underline"
				href="https://www.buymeacoffee.com/TheResonanceApp">Buy Me a Coffee</a
			>
				or use our <a href="/koha/">crypto wallet</a>.
			</p>
			<p class="text-gray-400 mt-4">© {new Date().getFullYear()} Resonance. All Rights Reserved.</p>
		</footer>
	</main>

	<footer class="sticky bottom-0 z-10 bg-surface-100-800-token border-surface-400-500-token">
		<BottomNav isLoggedIn={!!session} />
	</footer>
</div>
