<!-- src/routes/account/+page.svelte -->
<script lang="ts">
	import CategoryGrid from '$lib/components/category/CategoryGrid.svelte';

	export let data;

	let { categories, session } = data;
	$: ({ categories } = data);
</script>

<main class="flex flex-col items-center text-center mt-16">
	<h1 class="text-5xl font-extrabold">
		Welcome to <span class="gradient-heading">resonance*</span>
	</h1>
	<p class="text-gray-300 text-lg mt-4 max-w-2xl">
		A heart-centered platform connecting kiwis with like-minded individuals, businesses, and
		communities. Together, we're building a parallel marketplace for an abundant living without the
		middleman.
	</p>
	{#if session}
		<a class="btn btn-lg bg-primary-600 mt-6" href="/listings/create">Create a Listing</a>
	{/if}

	<!-- Call to Action Section -->

	<!-- CTA: Create a New Listing -->
	{#if !session}
		<div class="card mt-8 mb-8 p-6 rounded-lg text-center shadow-lg max-w-xl">
			<h2 class="text-white text-2xl font-bold gradient-heading">
				Get Started on Creating a New Listing
			</h2>
			<p class="text-gray-200 text-lg mt-2">
				Let others know about your offerings, services, or ideas by adding your own listing to
				Resonance. It's easy to get started, and your contribution helps grow our community.
			</p>
			<a class="btn btn-lg bg-primary-600 mt-6" href="/listings/create">Create a Listing</a>
		</div>
	{/if}
</main>

<hr class="mt-6 mb-6" />

<section class="flex flex-col items-center text-center">
	<h2 class="h2">Browse through our variety of categories</h2>
	<p class="text-gray-200 text-xl mt-8">
		To get started, browse through our categories below or explore all listings to find something
		that resonates with you!
	</p>
	<CategoryGrid {categories} />
</section>

<style>
    .gradient-heading {
        @apply bg-clip-text text-transparent box-decoration-clone;
        @apply bg-gradient-to-br;
        @apply from-primary-500 via-tertiary-500 to-secondary-500;
    }
</style>
