<script lang="ts">
	import ListingsRepository from '$lib/repositories/ListingsRepository';
	import type { SupabaseClient } from '@supabase/supabase-js';
	import { debounce } from 'throttle-debounce';
	import { createEventDispatcher, onDestroy, onMount } from 'svelte';

	export let supabase: SupabaseClient;
	export let cssClass: string = '';
	export let placeholder: string = 'Search...';
	export let searchTerm: string = '';

	const dispatch = createEventDispatcher();
	const repository = new ListingsRepository(supabase);
	const searchListings = debounce(200, async (query: string) => {
		dispatch('loading', true);
		await repository
			.searchByTitleAndDescription(query)
			.then((data) => {
				dispatch('results', data);
			})
			.catch((error) => {
				console.error(error);
				dispatch('results', []);
			})
			.finally(() => {
				dispatch('loading', false);
			});
	});

	function onInput(event: Event): void {
		const target = event.target as HTMLInputElement;
		searchTerm = target.value;
		if (searchTerm.length > 3) {
			searchListings(searchTerm);
		} else {
			dispatch('results', []);
		}
	}

	function handleKeyDown(event: KeyboardEvent) {
		if (event.key === 'Escape') {
			dispatch('results', []);
		}
	}

	onMount(() => {
		document.addEventListener('keydown', handleKeyDown);
	});

	onDestroy(() => {
		document.removeEventListener('keydown', handleKeyDown);
	});
</script>

<input bind:value={searchTerm} class={cssClass} on:input={onInput} {placeholder} type="search" />
