<script>
	export let text;
	export let url;
	export let ariaLabel = 'Share on Telegram';
	let classes = '';

	export { classes as class };

	import ShareButton from './ShareButton.svelte';

	let href;

	$: href = encodeURI(
		`https://t.me/share/url?url=${url}&text=${text}`
	);
</script>

<ShareButton {...$$restProps} {ariaLabel} class="ssbc-button--telegram {classes}" {href}>
	<svg fill="none" height="24" stroke="currentColor" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21.633 3.037c-.917.41-4.502 1.89-8.37 3.516-3.87 1.626-7.714 3.24-8.532 3.588-.821.347-1.851.683-1.993 1.375-.142.692.32 1.061.32 1.061s.986.454 1.123.502c.136.048 1.07.346 1.504.458.434.111 1.512.38 1.512.38s.697 2.137 1.061 3.211c.363 1.074.578 1.715.578 1.715s.29.847 1.01.847c.72 0 1.141-.543 1.141-.543l1.193-1.644 3.34 2.45s1.704.994 2.854.042c.575-.475.82-.93.922-1.287.102-.357.178-1.037.178-1.037s.993-6.158 1.832-11.494c.154-.987.091-1.576-.091-1.937-.183-.36-.656-.624-1.582-.203z"
			fill="white" />
	</svg>
</ShareButton>

<style>
    :global(.ssbc-button--telegram) {
        background-color: #0088cc;
    }

    :global(.ssbc-button--telegram:active),
    :global(.ssbc-button--telegram:hover) {
        background-color: #006699;
    }
</style>
