import { locationStore } from '$lib/stores';
import type { Coordinate } from '$lib/types';

export function getCurrentLocation(): Promise<{ longitude: number; latitude: number }> {
	return new Promise((resolve, reject) => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				resolve({
					longitude: position.coords.longitude,
					latitude: position.coords.latitude
				});
			},
			(error) => {
				console.error('Error getting current location:', error);
				reject(error);
			},
			{
				enableHighAccuracy: true,
				timeout: 10000,
				maximumAge: 0
			}
		);
	});
}

async function checkLocationCookies(): Promise<{
	exists: boolean;
	location?: { lng: number; lat: number };
}> {
	const response = await fetch('/api/user/location');
	return await response.json();
}

export async function setCurrentLocation(force = false): Promise<Coordinate | undefined> {
	try {
		if (!force) {
			const { exists, location } = await checkLocationCookies();
			if (exists && location) {
				locationStore.set(location);
				return location;
			}
		}

		const position = await getCurrentLocation();
		locationStore.set({
			lng: position.longitude,
			lat: position.latitude
		});

		await fetch('/api/user/location', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				lat: position.latitude,
				lng: position.longitude
			})
		});
		return {
			lng: position.longitude,
			lat: position.latitude
		};
	} catch (error) {
		console.log("couldn't get location");
	}
}
