<script lang="ts">
	import Icon from '@iconify/svelte';
	import { TabAnchor, TabGroup } from '@skeletonlabs/skeleton';
	import { page } from '$app/stores';

	export let isLoggedIn: boolean = false;
</script>

<TabGroup justify="justify-center">
	<TabAnchor href="/" selected={$page.url.pathname === '/'}>
		<svelte:fragment slot="lead"
		><span class="flex items-center justify-center"
		><Icon height="25px" icon="mdi-light:home" /></span
		></svelte:fragment
		>
		<span>Home</span>
	</TabAnchor>
	<TabAnchor href="/categories" selected={$page.url.pathname === '/categories'}>
		<svelte:fragment slot="lead"
		><span class="flex items-center justify-center"
		><Icon height="25px" icon="carbon:collapse-categories" /></span
		></svelte:fragment
		>
		<span>Categories</span>
	</TabAnchor>
	<TabAnchor href="/about" selected={$page.url.pathname === '/about'}>
		<svelte:fragment slot="lead"
		><span class="flex items-center justify-center"
		><Icon height="25px" icon="material-symbols:info-outline" /></span
		></svelte:fragment
		>
		<span>About</span>
	</TabAnchor>
	<TabAnchor href="/koha" selected={$page.url.pathname === '/koha'}>
		<svelte:fragment slot="lead"
		><span class="flex items-center justify-center"
		><Icon height="25px" icon="material-symbols:credit-card-heart" /></span
		></svelte:fragment
		>
		<span>Koha</span>
	</TabAnchor>
	<TabAnchor href="/maps" selected={$page.url.pathname === '/maps'}>
		<svelte:fragment slot="lead"
		><span class="flex items-center justify-center"
		><Icon height="25px" icon="material-symbols:map" /></span
		></svelte:fragment
		>
		<span>Maps</span>
	</TabAnchor>
	{#if isLoggedIn}
		<TabAnchor href="/account" selected={$page.url.pathname === '/account'}>
			<svelte:fragment slot="lead"
			><span class="flex items-center justify-center"
			><Icon height="25px" icon="material-symbols:account-circle" /></span
			></svelte:fragment
			>
			<span>Account</span>
		</TabAnchor>
	{/if}
</TabGroup>
