import type { SupabaseClient } from '@supabase/supabase-js';
import type { Database } from '$lib/db';
import type { Listing, ListingSearchResult, Location, NearbyListing, Profile } from '$lib/types';
import { getPublicURL, sanitize } from '$lib/utils/helper';

export type ListingUpdate = Database['public']['Tables']['listings']['Update'];

export default class ListingsRepository {
	private supabase: SupabaseClient;

	constructor(supabase: SupabaseClient) {
		this.supabase = supabase;
	}

	async isOwnerOfListing(userId: string, listingId: number) {
		const { data, error } = await this.supabase
			.from('listings')
			.select('id')
			.eq('user_id', userId)
			.eq('id', listingId)
			.single();

		if (error) {
			if (error.code === 'PGRST116') {
				return false;
			}
			throw error;
		}

		return data !== null;
	}

	async getOrCreateDraft(): Promise<Listing> {
		return this.supabase.rpc('get_or_create_draft_listing').then(({ data, error }) => {
			if (error) {
				console.error('Error receiving or creating draft listing:', error);
				throw error;
			}

			if (!data) {
				throw new Error('could not receive draft listing');
			}

			return this.constructListing(data);
		});
	}

	async getById(id: number, userId?: string): Promise<Listing | null> {
		return this.supabase
			.rpc('get_detailed_listing', { listing_id: id, current_user_id: userId })
			.then(({ data, error }) => {
				if (error) {
					console.error('Error retrieving listing:', error);
					throw error;
				}
				if (!data || data.id === null) {
					return null;
				}

				return this.constructListing(data);
			});
	}

	async deleteById(id: number): Promise<void> {
		const { error, count } = await this.supabase.from('listings').delete().eq('id', id);
		console.log(error, count);
		if (error) {
			throw new Error(error.message);
		}
	}

	async listingInView(
		min_lat: number,
		min_long: number,
		max_lat: number,
		max_long: number
	): Promise<NearbyListing[]> {
		const { data, error } = await this.supabase
			.rpc('listings_in_view', {
				min_long,
				min_lat,
				max_long,
				max_lat
			})
			.returns<NearbyListing[]>();

		if (error) {
			throw new Error(error.message);
		}

		return data.map((listing) => ({
			...listing,
			title: sanitize(listing.title)
		}));
	}

	async searchByTitleAndDescription(query: string): Promise<ListingSearchResult[]> {
		const { data, error } = await this.supabase
			.rpc('search_listings', { query })
			.returns<ListingSearchResult[]>();

		if (error) {
			throw new Error(error.message);
		}

		return data.map((listing) => ({
			...listing,
			title: sanitize(listing.title)
		}));
	}

	private constructListing(data: any): Listing {
		const location: Location = {
			place: data.location_name || 'Unknown Location',
			region: data.location_region || 'Unknown Region',
			distance: undefined,
			lat: Number(data.location_lat),
			lng: Number(data.location_lng)
		};

		const category = {
			id: data.category_id,
			name: data.category_name,
			slug: data.category_slug,
			path: data.category_path,
			parent: undefined
		};

		const profile: Profile = {
			id: data.profile_id,
			fullName: data.full_name,
			username: data.username
		};

		return {
			id: data.id,
			title: sanitize(data.title),
			description: sanitize(data.description),
			createdBy: profile,
			category: category,
			location: location,
			contactInfo: data.contact_info || {},
			isFavorite: data.is_favorite || false,
			eventDate: data.event_date,
			images: (data.images || []).map((image: any) => ({
				id: image.id,
				imageUrl: getPublicURL(this.supabase, image.image_url),
				isPrimary: image.is_primary
			}))
		};
	}
}
