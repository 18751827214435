import { PUBLIC_SENTRY_DSN } from '$env/static/public';
import { init } from '@jill64/sentry-sveltekit-cloudflare/client';
import type { HandleServerError } from '@sveltejs/kit';

const onError = init(PUBLIC_SENTRY_DSN);

export const handleError: HandleServerError = onError(async ({ error, event, status, message }) => {
	const errorId = crypto.randomUUID();

	console.error(error, event, status, message);

	return {
		message,
		status,
		errorId: errorId
	};
});
